/* General modal styling */
.data-modal {
  width: 70%;
  max-width: 90%;
  background-color: #fff;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--header-height, 20px); /* Dynamically set margin-top */
  max-height: var(
    --modal-max-height,
    calc(100vh - 40px)
  ); /* Adjusted dynamically */
  overflow-y: auto;
}

.data-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Align the modal at the top of the viewport */
  /* padding-top: 100px; Ensure the modal starts below the header */
}

/* Content inside the modal */
.modal-content {
  display: flex;
  width: 100%;
}

/* Image Section */
.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #3498db;
}

/* Details Section */
.details-section {
  flex: 2;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details-section h2 {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.details-section p {
  font-size: 16px;
  margin: 5px 0;
  color: #34495e;
}

.details-section p strong {
  font-weight: bold;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.close-button:hover {
  background-color: #c0392b;
}

.section-header {
  cursor: pointer;
  /* font-weight: bold; */
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.section-content {
  padding-left: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background-color: #d32f2f;
}
