/* Callbook.css */

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadButton {
  display: inline-block;
  align-self: end;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #0065ff;
  border: 1px solid #0065ff;
  border-radius: 4px;
}

.uploadButton:hover {
  background-color: #0065ff;
  border-color: #0065ff;
}

.tableContainer {
  width: 98%;
  margin-top: 20px;
  border: 1px solid #ddd;
  /* Add border for the table container */
  border-radius: 5px;
  overflow: hidden;
  /* Hide overflow content */

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    /* Add border for table cells */
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    /* Add background color for header cells */
  }

  tr {
    &:hover {
      background-color: #f5f5f5;
      /* Add hover effect for table rows */
    }
  }
}

.dark-theme .tableContainer tr:hover {
  background-color: #a9a9a9; /* Dark gray color for dark theme hover */
}

.pagination {
  display: flex;
  justify-content: flex-end;
  /* Align pagination to the right */
  margin-top: 20px;

  button {
    margin: -3px 6px 0 6px;
    padding: 6px 8px;
    border: none;
    background-color: #2196f3;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
      background-color: #ddd;
      color: #666;
      cursor: not-allowed;
    }
  }
}
