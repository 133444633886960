@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff2ff;
  overflow: auto;
}

.content-container {
  margin-right: 12px;
  /* width: calc(100vw - 90px) !important; */
  padding: 40px;
  height: 100%;
  overflow: auto;
  transition: margin-left 0.3s ease-in-out;
}

.product-name-sidebar {
  color: var(--White, #fff);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
  font-family: Lexend;
}

.submit_button {
  width: 60px;
  height: 44px;
  display: flex;
  padding: 21px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Primary-Primary, #0065ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
  color: white;
  cursor: pointer;
}

.submit_button:hover {
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    var(--Primary-Primary, #0065ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

.undo_button {
  display: flex;
  width: 60px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  outline: 1px solid #0065ff;
  outline-offset: -1px solid #0065ff;
  background: var(--Primary-Primary, #f2f7ff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
  color: #0065ff;
  cursor: pointer;
}

.undo_button:hover {
  border-radius: 8px;
  border: 0px solid var(--Border-Border_content_primary_shade02, #96c0ff);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ),
    var(--Button-button-background, #fff);
}

.submit_button_add {
  display: flex;
  width: 280px;
  height: 38px;
  padding: 7px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  border-radius: 2px;
  border: 1px solid #dde2e4;
}

.submit_button_minus {
  display: flex;
  width: 280px;
  height: 38px;
  padding: 7px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  border-radius: 2px;
  border: 1px solid #dde2e4;
  color: #fff;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.sidebar {
  display: flex;
  width: 64px;
  padding-top: 30px;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
  border-top: 1px solid #96c0ff;
  border-right: 1px solid #96c0ff;
  border-bottom: 1px solid #96c0ff;
  background: linear-gradient(180deg, #2b7fff 0%, #091e42 100%);
  /* background-color: #191818; */
  color: white;
  transition: width 0.3s ease-in-out;
  position: fixed;
  height: 100vh;
  z-index: 10000;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar:hover {
  width: 240px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.sidebar:hover .sidebar_container {
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 8px 0px 0px 8px;
}

.sidebar_container {
  cursor: pointer;
}

@media print {
  #sidebar {
    display: none;
  }
  #header {
    display: none;
  }
  #peopleManagementTable {
    position: absolute;
    width: 100%;
  }
}

/* 
.sidebar:hover .selected_sidebar {
  width: 90%;
  margin-left: 10px;
  padding: 12px 12px;
  display: flex;
  border-radius: 8px 0px 0px 8px;
  background-color: #f2f7ff;
} */

.sidebar-hidden {
  display: none;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo:hover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected_sidebar {
  width: 80%;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  border-radius: 8px 0px 0px 8px;
  border-radius: 8px 0px 0px 8px;
  color: '';
  background-color: #f2f7ff;
}

.header {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 11px 10px 5px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 0px 12px 12px;
  border-bottom: 1px solid #e6f0ff;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  color: var(--Text-Primary-Color, #0d1826);
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 1001;
}

.line {
  display: flex;
  flex-direction: column;
  transform: rotate(90deg);
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  height: 100%;
  border-left: 1px solid var(--Border-border_content, #e2e2e2);
  background: var(--Content-content_background, #fff);
}

/* customized CSS */

.ant-col {
  padding-right: 0px !important;
}

.ant-select-single {
  width: 188px;
  height: 40px;
}

.rows-per-page .ant-select-single {
  width: 3.5rem;
}

.ant-btn.ant-btn-circle.ant-btn-lg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
  border: 0px;
  box-shadow: none;
}

.ant-btn.ant-btn-circle.ant-btn-lg:hover {
  border: 0px solid var(--Border-Border_content_primary_shade02, #96c0ff);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ),
    var(--Button-button-background, #fff);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-modal
  .ant-modal-footer
  .ant-btn
  + .ant-btn:not(.ant-dropdown-trigger) {
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.leaflet-container a.leaflet-popup-close-button {
  width: 35px !important;
}

.ant-popover .ant-popover-inner {
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 10px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 10px 0px 5px 0px;
  align-items: center;
}
.dark
  :where(.css-dev-only-do-not-override-1okl62o).ant-breadcrumb
  li:last-child {
  color: white;
}
.dark .ant-breadcrumb-separator {
  color: white !important;
}
/* :where(.css-dev-only-do-not-override-1okl62o).ant-breadcrumb li:last-child {
  color: antiquewhite;
} */
.ant-btn {
  background: #0065ff;
  margin-left: 20px;
}

.ant-select {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  width: 100%;
  height: 75% !important;
  padding: 0 11px;
}

.custom-ant-select .ant-select .ant-select-selector {
  border: 0px !important;
  background: transparent;
}
.dark .ant-select-selection-item {
  color: white;
}

.dark .ant-picker-range-separator {
  color: white;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 0px solid transparent; /* Set border to 0px and color to transparent */
  background: transparent; /* Set background to transparent */
}

:where(.css-dev-only-do-not-override-1okl62o).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 0px;
}

.entry {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid green;
  color: green;
  font:
    14px Arial,
    sans-serif;
  font-weight: bold;
}

.exit {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid red;
  color: red;
  font:
    14px Arial,
    sans-serif;
  font-weight: bold;
}

.css-1atu56z-MuiPaper-root {
  z-index: 99999 !important;
}
.css-46bh2p-MuiCardContent-root {
  padding: 0 !important;
}

.journey-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #0065ff;
  border: 1px solid #0065ff;
  border-radius: 4px;
}

.custom-button:hover {
  background-color: #0065ff;
  border-color: #0065ff;
}

.checkbox-main {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-inner:hover,
.custom-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  background-color: #2cd074 !important; /* Keeps the background color when checked or hovered */
  border-color: #2cd074 !important; /* Ensures the border color is consistent */
}

#map {
  height: 90vh;
  width: 100%;
}
.leaflet-container {
  height: 420px;
  width: 100%;
}

@media (max-width: 1024px) {
  .ant-row-middle {
    gap: 10px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
