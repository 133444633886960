/* Notification.css */

.notification {
  position: absolute;
  top: 6rem;
  right: 20px;
  background-color: #f44336;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(244, 67, 54, 0.3);
  z-index: 999;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.notification.closed {
  opacity: 0;
  pointer-events: none;
}
